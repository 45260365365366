<template>
  <div>
    <!-- <div class="header-for-bg">
      <div class="background-header position-relative">
        <img :src="coverImage" alt="header-bg" class="img-fluid w-100">
        <div class="title-on-header">
          <div class="data-block">
            <h2>Your mediaData</h2>
          </div>
        </div>
      </div>
    </div> -->
    <b-row class="row" id="scrollRow">
      <b-col sm="12">
      </b-col>
      <b-col md="6" lg="4" class="mb-3" v-if="propShowAdd">
        <div class="user-images position-relative overflow-hidden">
          <img :src="addImage" v-if="propMediaType === 'photo'" class="img-fluid rounded pointer" alt="Responsive image">
          <img :src="addImage" v-else class="img-fluid rounded" @click="showModelVideo = true">
          <input type="file" v-if="propMediaType === 'photo'" style="height: 100%;" class="uploadImagesInput pointer" title="Select photos" id="inputPhoto" ref="inputPhoto"  @change="onImageChange" multiple accept="image/png,image/jpeg,image/jpg" />
        </div>
      </b-col>
      <b-col v-for="(item,index) in vmImagePreview" :key="index+'preview'" md="6" lg="4" class="mb-3 previewImages">
        <div class="user-images position-relative overflow-hidden pointer">
          <img v-if="propMediaType === 'photo'" :src="item.image" class="img-fluid rounded" alt="Responsive image">
          <iframe v-else :src="item.embeded" class="rounded" style="width: 100%; height: 150px;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </b-col>
      <b-col v-for="(item,index) in mediaData" :key="index+'media'" md="6" lg="4" class="mb-3">
        <div class="user-images position-relative overflow-hidden pointer">
          <img @click="showModelMedia = true; mediaIndex = index" v-if="propMediaType === 'photo'" :src="item.image" class="img-fluid rounded" alt="Responsive image">
          <iframe v-else :src="item.embeded" class="rounded" style="width: 100%; height: 100%;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <a @click="deleteMedia(item, index)" v-if="propShowAdd" class="image-edit-btn" data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit or Remove"><i class="fa-solid fa-trash"></i></a>
        </div>
      </b-col>
      <b-col lg="12">
        <div v-if="!allLoaded" v-infinite-scroll="getAllImages" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
          <b-spinner variant="primary" label="Spinning" >
          </b-spinner>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-model="showModelMedia"
      scrollable
      size="xl"
      hide-footer no-close-on-backdrop
      modal-class="mediaDialog">
      <MediaView :propMedia="mediaData" :propIndex="mediaIndex" />
    </b-modal>
    <b-modal
      v-model="showModelVideo"
      :title="cvVideoAddTitle">
      <template>
        <div>
          <b-row>
            <b-col lg="12">
              <div class="col-md-12 mb-3">
                <label for="validationcarr_field">
                  Video link
                </label>
                <input
                  v-model="vmVideoLink"
                  type="text"
                  class="form-control"/>
                <span style="font-size: 13px;"><span style="color:red">* </span>{{cvVideoUrlValidMsg}}</span>
              </div>
            </b-col>
            <b-col lg="12">
              <ul class="videoLinkList">
                <li v-for="(item, index) of allVideoLinks" :key="index">
                  {{item.video}}
                  <i class="fa-solid fa-circle-xmark closeIcon" @click="removeVideoLink(index)"></i>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </template>
      <template #modal-footer="">
        <!-- <b-button size="sm" class="pull-left" @click="addVideoLink()">
          Add
        </b-button> -->
        <b-button size="sm" class="pull-left" @click="addVideos()">
          Submit
        </b-button>
        <b-button size="sm" class="pull-left" @click="showModelVideo = false">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </div>
</template>
<style lang="scss" scoped>
  .header-for-bg {
    margin-top: 0 !important;
    margin-bottom: 18px !important;
  }
  .user-images {
    height: 232px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .image-edit-btn {
      cursor: pointer;
    }
  }
  .hide-show-more {
    position: absolute;
    opacity: 0;
  }
  .uploadImagesInput {
    position: absolute;
    width: 100%;
    left: 0;
    opacity: 0;
    top: 0;
  }
  .previewImages {
    img {
      background-attachment: fixed;
      opacity: 0.5;
      &:before{
        content: "Joe's Task: ";
      }
    }
  }
</style>
<script>
import { socialvue } from "../../config/pluginInit"
import { Images } from "../../FackApi/api/image"
import { Videos } from "../../FackApi/api/video"
import ApiResponse from "../../Utils/apiResponse.js"
import MediaView from "./MediaView.vue"
import addImage from "/src/assets_gide/img/img/addPic.webp"
// import grey from '../../assets_gide/img/banners/e5e5e5_grey.png'

export default {
  name: "media",
  components: {
    MediaView
  },
  data () {
    return {
      title: "Media",
      coverImage: "grey",
      mediaData: [],
      busy: false,
      offset: 0,
      limit: 18,
      allLoaded: false,
      observer: null,
      module_id: "",
      type: "",
      mediaIndex: 0,
      showModelMedia: false,
      addImage: addImage,
      vmImageData: Object.assign({}, this.initImageData()),
      vmImagePreview: [],
      cvVideoAddTitle: "Add Video",
      showModelVideo: false,
      vmVideoLink: null,
      allVideoLinks: [],
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Media",
      cvVideoUrlValidMsg: "Currently only youtube links are supported"
    }
  },
  props: {
    propModuleId: {
      default: ""
    },
    propType: {
      type: Number,
      default: 5
    },
    propMediaType: {
      type: String,
      default: "photo"
    },
    propModuleType: {
      type: String,
      default: "UNIV"
    },
    propShowAdd: {
      type: Boolean,
      default: false
    }
  },
  async mounted () {
    socialvue.index()
    if (this.propModuleId && this.propType) {
      this.module_id = this.propModuleId
      this.type = this.propType
    }
    else {
      this.module_id = this.$route.params.module_id
      this.type = this.$route.params.type
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * initImageData
    */
    initImageData () {
      return {
        module_id: "",
        module_name: this.propModuleType,
        image: [],
        image_type: {}
      }
    },
    /**
     * getAllImages
     */
    async getAllImages () {
      if (this.allLoaded) {
        return
      }
      const mediaObj = {
        module_id: this.module_id,
        type: this.type,
        limit: 20,
        offset: this.offset
      }
      this.busy = true
      let mediaResp = null
      if (this.propMediaType === "video") {
        mediaResp = await Videos.videoListCustomType(this, mediaObj)
      }
      else {
        mediaResp = await Images.imageListCustomType(this, mediaObj)
      }
      if (mediaResp && mediaResp.resp_status) {
        this.mediaData.push(...mediaResp.resp_data.data)
        this.offset = this.offset + this.limit
      }
      else {
        this.allLoaded = true
      }
      this.busy = false
    },
    /**
     * onImageChange
    */
    async onImageChange (e) {
      try {
        let input = e.target
        if (input.files.length > 0) {
          this.cvImageReading = true

          let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]

          let imageValidExtension = input.files.length > 0 ? Object.values(input.files).map(file => allowedExtension.includes(file.type)) : null

          if (imageValidExtension && imageValidExtension.includes(false)) {
            this.showToast = true
            this.toastMsg = "Only jpeg, webp and png is supported."
          }
          else {
            this.cvImageError = false
            this.cvImageErrorAlert = false
            this.toastMsg = ""
          }

          let images = Object.values(input.files).slice(0, 5)

          this.vmImageData.image = images

          for (const index in images) {
            let image = images[index]
            let imageData = {}
            const reader = new FileReader()
            this.vmImageData.image_type[image.name] = 5
            reader.onload = async (e) => {
              let dataUrl = await e.target.result
              imageData = {
                image: dataUrl,
                image_medium: dataUrl,
                image_thumbnail: ""
              }
              this.$set(this.vmImagePreview, index, imageData)
            }
            reader.readAsDataURL(image)
            imageData.image_medium = ""
          }
          this.imageAdd(this.module_id)
          this.cvImageReading = false
        }
      }
      catch (err) {
        this.cvImageReading = false
        console.error("Exception occurred at onImageChange() and Exception:", err.message)
      }
    },
    /**
    * imageAdd
    */
    async imageAdd (moduleId) {
      try {
        this.vmImageData.module_id = moduleId
        const imageAddResp = await Images.imageAdd(this, this.vmImageData)
        await ApiResponse.responseMessageDisplay(this, imageAddResp)

        if (imageAddResp && !imageAddResp.resp_status) {
          return false
        }
        else {
          this.mediaData.unshift(...imageAddResp.resp_data)
          this.vmImagePreview = []
        }

        this.vmImageData = Object.assign({}, this.initImageData())
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
      }
    },
    /**
     * deleteMedia
     */
    async deleteMedia (item, index) {
      let deleteResp = null
      if (this.propMediaType === "video") {
        deleteResp = await Videos.videoDeleteByID(this, item.video_id)
      }
      else {
        deleteResp = await Images.imageDeleteByID(this, item.image_id)
      }
      if (deleteResp && deleteResp.resp_status) {
        this.mediaData.splice(index, 1)
      }
    },
    /**
     * addVideoLink
     */
    addVideoLink () {
      if (this.vmVideoLink) {
        let youtubeUrlRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
        let videoIdRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
        let obj = {
          video: this.vmVideoLink,
          embeded: ""
        }
        // check url is whether youtube url or not
        let validUrl = youtubeUrlRegex.test(this.vmVideoLink)
        if (!validUrl) {
          this.showToast = true
          this.toastMsg = this.cvVideoUrlValidMsg
          this.vmVideoLink = ""
          return false
        }
        let videoIdFound = this.vmVideoLink.match(videoIdRegex)
        if (!videoIdFound) {
          this.vmVideoLink = ""
          this.showToast = true
          this.toastMsg = this.cvVideoUrlValidMsg
          return false
        }
        else {
          this.showToast = false
          this.toastMsg = ""
          let videoId = videoIdFound[1]
          obj.embeded = `https://www.youtube.com/embed/${videoId}`
        }
        this.allVideoLinks.push(obj)
        this.vmVideoLink = null
        return true
      }
    },
    /**
     * addVideos
     */
    async addVideos () {
      if (!this.addVideoLink()) {
        return
      }
      const videoObj = {
        module_id: this.propModuleId,
        module_name: this.propModuleType,
        videosLinks: JSON.stringify(this.allVideoLinks)
      }
      const videoAddResp = await Videos.videoAdd(this, videoObj)
      if (videoAddResp && videoAddResp.resp_status) {
        this.showModelVideo = false
        this.vmVideoLink = null
        this.mediaData.unshift(...[ videoAddResp.resp_data ])
        this.allVideoLinks = []
      }
    },
    /**
     * removeVideoLink
     */
    removeVideoLink (index) {
      this.allVideoLinks.splice(index, 1)
    }
  }
}
</script>
